<template>
  <div class="outer-page background-colors">
    <div>预警消息</div>
    <div class="warning-info">
      <ul>
        <li v-for="(item, index) in data" :key="index">
          <span style="display: flex">
            <span
              class="iconfont icon-jinggao"
              style="margin-right: 10px"
            ></span>
            <span>{{ item.leaveName }}</span>
          </span>
          <span>{{ item.createTime }}</span>
        </li>
      </ul>
    </div>
    <div class="left-top-corner"></div>
    <div class="right-top-corner"></div>
    <div class="right-bottom-corner"></div>
    <div class="left-bottom-corner"></div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    currentMapInfo: Object,
  },
  data() {
    return {
      data: [],
    };
  },
  methods: {
    getList() {
      this.$post(this.$api.PRODUCE_MAPINFO.WARNING, {
        searchCompanyNo: this.currentMapInfo.companyNo,
        farmId: this.currentMapInfo.sourceId,
      }).then((res) => {
        this.data = res.list;
      });
    },
  },
  watch: {
    currentMapInfo(n) {
      if (n == null) return;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
.outer-page {
  width: 100%;
  height: 100%;
  // background: rgba(23, 34, 60, 0.6);
  border-radius: 4px;
  color: #fff;
  padding: 10px;
  .warning-info {
    margin-top: 10px;
    ul {
      li {
        display: flex;
        justify-content: space-between;
      }
    }
    .icon-jinggao {
      color: #f9ff58;
    }
  }
}
</style>